import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
// import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } 			from '@angular/forms';
import { ApiService } 									from  '../api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-tab4-edit',
  templateUrl: './tab4-edit.page.html',
  styleUrls: ['./tab4-edit.page.scss'],
})
export class Tab4EditPage implements OnInit {

	submitted = false;
	data: any;
	options: any;
	cities: any;
	form: FormGroup;
	selectedState: {};

	@ViewChild(IonContent, {static: true}) content: IonContent;

	constructor(
		private modalController: ModalController,
  		private navParams: NavParams,
  		private fb: FormBuilder,
  		private apiService: ApiService
  	) { }

	get f() { return this.form.controls; }

	ngOnInit() {

		this.data          = this.navParams.get('data');
	  	this.options       = this.navParams.get('options');

	  	this.form = this.fb.group({
				avatar_base64: 	['', 	[]],
				name: 			['', 	[Validators.required]],
				since_date: 	['',	[Validators.required]],
				state_id: 		['', 	[Validators.required]],
				city_id: 		['', 	[Validators.required]],
				description: 	['', 	[Validators.required]],
				facebook_link: 	['', 	[]],
				google_link: 	['', 	[]],
				twitter_link: 	['', 	[]],
				linkedin_link: 	['', 	[]],
			});

	  	this.index();
	}

	index() {


	}

	stateChanged(event) {

  	// alert($event.target.value);
  	// this.selectedState = event.target.value;
  	// console.log(this.selectedState);
  	
  	// this.form.controls['state_id'].setValue(this.selectedState['id']);
  	// this.form.controls['city_id'].setValue('');
  	if(event !== null)
  		this.cities = (this.options.states.filter(state => state.id == event.target.value))[0].cities;
  	else
  		this.cities = (this.options.states.filter(state => state.id == this.form.controls['state_id'].value))[0].cities;
  }

  dateChanged(event) {

  	if(event.target.value !== this.form.controls['since_date'].value)
  		this.form.controls['since_date'].setValue(moment(event.target.value).format('YYYY-MM-DD'));
  }

  submit() {
  	this.submitted = true;

  	if(this.form.valid) {
  		
		this.apiService.createTeam(this.form.value).subscribe(data => {
			this.submitted = false;  
			
			if (data.status == true) {
				this.dismissModal();
			} else {
				alert(data.message);
			}
		});
  	}
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }



}
