import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonSlides, IonContent, ModalController, AlertController, NavParams } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators }       from '@angular/forms';
import * as moment from 'moment';
import { ApiService }                   from  '../api.service';

@Component({
  selector: 'app-tab1-courts-details',
  templateUrl: './tab1-courts-details.page.html',
  styleUrls: ['./tab1-courts-details.page.scss'],
})
export class Tab1CourtsDetailsPage implements OnInit {

  loading = true;
  submitted = false;
  courtType: any;
  data: any;
  mapurl:SafeResourceUrl;
  form: FormGroup;

  minDate: any;
  maxDate: any;
  today: any;
  
  minStart: any;
  maxStart: any;

  minEnd: any;
  maxEnd: any;

  date: any;
  end_date: any;
  startHours: any;
  endHours: any;
  size: any;
  preferGrass = false;
  duration: any;

  minDuration = "60";
  maxDuration = "180";



	@ViewChild(IonContent, {static: true}) content: IonContent;
  @ViewChild('slides', {static: true}) slides: IonSlides;


  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private dom: DomSanitizer,
    private fb: FormBuilder,
    public alertController: AlertController,
    private apiService: ApiService) {}

  get f() { return this.form.controls; }

  ngOnInit() {
    this.data         = this.navParams.get('data');

    this.form = this.fb.group({
      date:          ['',   [Validators.required]],
      end_date:      ['',   []],
      start_hours:   ['',   [Validators.required]],
      end_hours:     ['',   []],
      duration:      ['',   []],
      court_quantity:['',   []],
      court_size_id: ['',   [Validators.required]],
      prefer_grass:  [false, [Validators.required]],
      block_booking: [false, [Validators.required]],
    });

    this.initDatetime();
    this.initStartHours();

    this.loading = false;

    this.validateBlockBookingChanges()
  }

  validateBlockBookingChanges() {
    this.form.get('block_booking').valueChanges.subscribe((isBlockBooking: boolean) => {
      if (isBlockBooking) {
        // Block booking is enabled
        this.form.get('end_date').setValidators([Validators.required]);
        this.form.get('end_hours').setValidators([Validators.required]);
        this.form.get('court_quantity').setValidators([Validators.required]);
        this.form.get('duration').clearValidators();
      } else {
        // Block booking is disabled
        this.form.get('end_date').clearValidators();
        this.form.get('end_hours').clearValidators();
        this.form.get('court_quantity').clearValidators();
        this.form.get('duration').setValidators([Validators.required]);
      }
      
      // Update the validity of all fields after setting validators
      this.form.get('end_date').updateValueAndValidity();
      this.form.get('end_hours').updateValueAndValidity();
      this.form.get('court_quantity').updateValueAndValidity();
      this.form.get('duration').updateValueAndValidity();
    });
  }

  ionViewDidEnter() {
    this.getUrl();
  }

  scrollTo(elementId: string) {
      let y = document.getElementById(elementId).offsetTop;
      this.content.scrollToPoint(0, y, 1000);
  }

  initDatetime() {

    this.minDate = moment().format("YYYY-MM-DD");
    this.maxDate = moment().add(1, 'month').format("YYYY-MM-DD");
    this.today   = moment().format("YYYY-MM-DD");

    // this.form.controls['date']    .setValue(this.today);
    // this.form.controls['duration'].setValue({lower:'60',upper:'180'});
  }

  initStartHours() {

    var now = moment();

    if(now.format('HHmm') > "0959") {
      const remainder = 30 - (now.minute() % 30);

      this.minStart = moment(now).add(remainder, "minutes").format('YYYY-MM-DDTHH:mm');

      now.set('hour', 23);
      now.set('minute', 0);

      this.maxStart = moment(now).format('YYYY-MM-DDTHH:mm');
    }
    else {
      this.minStart = moment(now).set('hour', 10).set('minute', 0).format('YYYY-MM-DDTHH:mm');
      this.maxStart = moment(now).set('hour', 23).set('minute', 0).format('YYYY-MM-DDTHH:mm');
    }



  }

  selected(data, value) {

    if(data === 'court') {
      this.courtType = value;
    }
  }

  onDateChanged(event) {

    this.date = moment(event.target.value).format('YYYY-MM-DD')
    this.form.controls['date'].setValue(this.date);
     
    var current = moment().format('YYYY-MM-DD');

    let selectedDate = moment(this.date);
    console.log(selectedDate)

    if(this.date > current) {
      // console.log('higher date');
      selectedDate.set('hour', 10);
      selectedDate.set('minute', 0);

      // this.form.controls['start_hours'].setValue(this.date);
      this.minStart = moment(selectedDate).format('YYYY-MM-DDTHH:mm');
      this.maxStart = moment(selectedDate).set('hour', 23).set('minute', 0).format('YYYY-MM-DDTHH:mm');



      // if(moment().format()) 

      console.log('Min: ' + this.minStart);
      console.log('Max: ' + this.maxStart);
    }
    else {
      // console.log('lower date');
      // var now = moment();

      // const remainder = 30 - (now.minute() % 30);

      // this.minStart = moment(now).add(remainder, "minutes").format('YYYY-MM-DDTHH:mm');

      // now.set('hour', 23);
      // now.set('minute', 0);

      // this.maxStart = moment(now).format('YYYY-MM-DDTHH:mm');
    }

    



    // var now = moment(this.date);

    // const remainder = 30 - (now.minute() % 30);


    // now.set('hour', 23);
    // now.set('minute', 0);

    // this.maxStart = moment(now).format('YYYY-MM-DDTHH:mm');

    // this.recalculatePrice();
  }

  onEndDateChanged(event) {
    this.end_date = moment(event.target.value).format('YYYY-MM-DD')
    this.form.controls['end_date'].setValue(this.end_date);
     
    var current = moment().format('YYYY-MM-DD');

    let selectedDate = moment(this.end_date);
    console.log(selectedDate)

    if(this.end_date > current) {
      // console.log('higher date');
      selectedDate.set('hour', 10);
      selectedDate.set('minute', 0);

      // this.form.controls['start_hours'].setValue(this.end_date);
      this.minStart = moment(selectedDate).format('YYYY-MM-DDTHH:mm');
      this.maxStart = moment(selectedDate).set('hour', 23).set('minute', 0).format('YYYY-MM-DDTHH:mm');



      // if(moment().format()) 

      console.log('Min: ' + this.minStart);
      console.log('Max: ' + this.maxStart);
    }
  }

  onStartChanged(event) {

    let hours = moment(event.target.value).format('HH:mm');
    let datetime = this.date + 'T' + hours;

    this.form.controls['start_hours'].setValue(datetime);
  }

    onEndChanged(event) {

    let hours = moment(event.target.value).format('HH:mm');
    let datetime = this.end_date + 'T' + hours;

    this.form.controls['end_hours'].setValue(datetime);
  }

  getUrl() {
    // document.getElementById("myframe").setAttribute("src", this.data.map_url);
  }

  dismissModal(status = false) {

    this.modalController.dismiss({
      'dismissed': true,
      'status': status ? true: false
    });
  }

  selectedIndex: number = null;
  hasGrass: boolean = false;

   sizeClicked(size, index: number) {

     this.size = size;

      this.hasGrass = size.types.filter((item)=> {
          return item.type_id === 3;
      }).length > 0;

      this.selectedIndex = index;

      this.form.controls['court_size_id'].setValue(this.size.id);
   }

   recalculatePrice() {  

     if(this.startHours && this.endHours)
       this.duration = moment.duration(moment(this.endHours).diff(moment(this.startHours))).asHours();
   }

   createBooking() {
     this.apiService.createBooking(this.form.value).subscribe(data => {
        this.submitted = false;  
        
        if (data.status == true) {
          this.dismissModal(data.status);
        } else {
          alert(data.message);
        }
      });
   }

   async placeBookingDialog() {

     this.submitted = true;

     let self = this;
     if(this.form.valid) {

         let controls = this.form.controls;

          const alert = await this.alertController.create({
            header: 'Confirm Details',
            message: 
              'Please confirm the details before proceeding.<br><br>' +
              'Date: ' + moment(controls['date'].value).format('ddd DD, MMM') + 
              '<br>Time: ' + moment(controls['start_hours'].value).format('h:mm A') + 
              (!controls['block_booking'].value ? 
                '<br>Duration: ' + controls['duration'].value + ' hour(s)' : '') +
              '<br>Court: ' + this.data.name +
              '<br>Size: ' + this.size.size.name + 
              (controls['prefer_grass'].value ? ' (Grass)' : '') + 
              (controls['block_booking'].value ? 
                '<br><br><strong>Block Booking Details:</strong>' +
                '<br>End Date: ' + moment(controls['end_date'].value).format('ddd DD, MMM') +
                '<br>End Time: ' + moment(controls['end_hours'].value).format('h:mm A') +
                '<br>Number of Courts: ' + controls['court_quantity'].value
              : ''),
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  
                }
              }, {
                text: 'Confirm',
                handler: () => {
                   this.createBooking();
                }
              }
            ]
          });
          await alert.present();
        }
      }
}
