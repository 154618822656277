import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
// import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators }       from '@angular/forms';
import { ApiService }                   from  '../api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionSheetController }   from '@ionic/angular';
import * as moment from 'moment';
// import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-tab4-team',
  templateUrl: './tab4-team.page.html',
  styleUrls: ['./tab4-team.page.scss'],
})
export class Tab4TeamPage implements OnInit {

	segmentModel = "overview";

  data:any;
  loading = false;
  invited = false;
  member: any;
  searchCode = '';
  squadData: any;
  statsData: any;

  buttonsArray: Object[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public actionSheetController: ActionSheetController,
    ) { }

  ngOnInit() {

    // if(this.navParams.get('id'))
      this.index(this.route.snapshot.paramMap.get('id'));
  }

  index(id) {

    this.apiService.getTeam(id).subscribe(data => {
      
      if (data.status == true) {
        this.data = data.data;
        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  segmentChanged(event) {

      if(event.target.value === 'stats')
        this.stats();
  }

  squad() {

    this.loading = true;

    this.apiService.squadIndex(this.data.id).subscribe(data => {

          this.loading = false;
          
          if (data.status == true) {
            this.squadData = data.data;
          } else {
            alert(data.message);
          }
        });

  }

  stats() {

    this.loading = true;

    this.apiService.statsIndex(this.data.id).subscribe(data => {

          this.loading = false;
          
          if (data.status == true) {
            this.statsData = data.data;
            console.log(this.statsData);
          } else {
            alert(data.message);
          }
        });

  }

  isOwnTeam(): boolean {
    if (localStorage.getItem('user') !== null) {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user) {
        let own = user.id === this.data.owner_user_id;

        if(!own) {

          if(this.squadData.manager && user.id === this.squadData.manager.user_id)
            return true;
        }
        return own;
      }
    }
    return false;
  }

  searchMember(event) {

    if(event.target.value !== '') {
        this.loading = true;
        this.apiService.getMember(event.target.value).subscribe(data => {

          this.loading = false;
          
          if (data.status == true) {
            this.member = data.data;
          } else {
            alert(data.message);
          }
        });
    }
  }

  clearSearch(event) {

    if(event.target.value === '')
      this.member = null;
  }

  cancelSearchMember(event) {

    // console.log(event.target.value);

    this.member = null;
  }

  inviteToTeam(userId) {

    this.invited = true;

    let param = {
      'team_id': this.data.id,
      'user_id': userId
    }

    this.apiService.inviteMember(param).subscribe(data => {

      this.invited = false;
      
      if (data.status == true) {

        this.member = null;
        this.searchCode = '';

        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  cancelInvite(id) {

    this.apiService.cancelInvite(id).subscribe(data => {

      // this.invited = false;
      
      if (data.status == true) {

        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  assign(data) {
    this.loading = true;

    this.apiService.addPosition(data).subscribe(data => {

      this.loading = false;
      if (data.status) {
        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  assignMember(data) {

    this.loading = true;
    this.apiService.addPosition(data).subscribe(data => {

      this.loading = false;
      if (data.status) {
        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  endPosition(id) {

    this.loading = true;
    this.apiService.endPosition(id).subscribe(data => {

      this.loading = false;
      if (data.status) {
        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  removeMember(id) {

    this.loading = true;
    this.apiService.removeMember(id).subscribe(data => {

      this.loading = false;
      if (data.status) {
        this.squad();
      } else {
        alert(data.message);
      }
    });
  }

  async presentAssignOptions(member) {

    if(!this.isOwnTeam()) 
      return false;

    let self = this;
    let teamId = this.data.id;

    for (let i = this.squadData.options.positions.length - 1; i >= 0; i--) {
       self.buttonsArray[i] = {
         'text': self.squadData.options.positions[i].name,
         'icon': 'football-outline',
         handler: () => {
            self.assign({ 'team_member_id': member.id? member.id: '', 'position_id': self.squadData.options.positions[i].id, 'team_id': teamId });
          }
        };
    }

    self.buttonsArray.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });

    const actionSheet = await this.actionSheetController.create({
      header: 'Assign Position',
      buttons: this.buttonsArray
    });
    await actionSheet.present();
  }

  async presentAssignMemberOptions(type) {

    if(!this.isOwnTeam()) 
      return false;

    let id;

    if(type === 'manager')
      id = 1;
    else if(type === 'coach')
      id = 2;

    let self = this;

    let buttonsArray: Object[] = [];

    for (let i = this.squadData.options.members.length - 1; i >= 0; i--) {
       buttonsArray[i] = {
         'text': self.squadData.options.members[i].user.name,
         'icon': 'person-outline',
         handler: () => {
            self.assignMember({ 'position_id': id, 'team_member_id': self.squadData.options.members[i].id });
          }
        };
    }

    buttonsArray.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });

    const actionSheet = await this.actionSheetController.create({
      header: 'Assign Member',
      buttons: buttonsArray
    });
    await actionSheet.present();
  }
  async presentOptions(position) {

    if(!this.isOwnTeam()) 
      return false;

    let self = this;

    let buttonsArray = [{
         'text': 'View Player Profile',
         'icon': 'person-circle-outline',
         handler: () => {
            
          }
        },
        {
         'text': 'End Current Position',
         'icon': 'close-outline',
         handler: () => {
             self.endPosition(position.id);
          }
        },
        {
         'text': 'Remove From Team',
         'icon': 'trash-outline',
         handler: () => {
            self.removeMember(position.team_member_id);
          }
        },{
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
        ];

    const actionSheet = await this.actionSheetController.create({
      header: 'Select An Option',
      buttons: buttonsArray
    });
    await actionSheet.present();
  }
}
