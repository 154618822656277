import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TabsTrainingAddPageRoutingModule } from './tabs-training-add-routing.module';

import { TabsTrainingAddPage } from './tabs-training-add.page';

import { ReactiveFormsModule }         from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TabsTrainingAddPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [TabsTrainingAddPage]
})
export class TabsTrainingAddPageModule {}
