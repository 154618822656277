import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'tab1-matches',
    loadChildren: () => import('./tab1-matches/tab1-matches.module').then( m => m.Tab1MatchesPageModule)
  },
  {
    path: 'tab1-events',
    loadChildren: () => import('./tab1-events/tab1-events.module').then( m => m.Tab1EventsPageModule)
  },
  {
    path: 'tab1-courts',
    loadChildren: () => import('./tab1-courts/tab1-courts.module').then( m => m.Tab1CourtsPageModule)
  },
  {
    path: 'tab4-team',
    loadChildren: () => import('./tab4-team/tab4-team.module').then( m => m.Tab4TeamPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./auth/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./auth/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'tab5-edit',
    loadChildren: () => import('./tab5-edit/tab5-edit.module').then( m => m.Tab5EditPageModule)
  },
  {
    path: 'tab4-edit',
    loadChildren: () => import('./tab4-edit/tab4-edit.module').then( m => m.Tab4EditPageModule)
  },
  {
    path: 'tabs-training',
    loadChildren: () => import('./tabs-training/tabs-training.module').then( m => m.TabsTrainingPageModule)
  },
  {
    path: 'tabs-training-add',
    loadChildren: () => import('./tabs-training-add/tabs-training-add.module').then( m => m.TabsTrainingAddPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
