import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tab1-matches-details',
  templateUrl: './tab1-matches-details.page.html',
  styleUrls: ['./tab1-matches-details.page.scss'],
})
export class Tab1MatchesDetailsPage implements OnInit {

  courtType: any;

	@ViewChild(IonContent, {static: true}) content: IonContent;


  constructor(private modalController: ModalController,
  		private data: NavParams) {}

  ngOnInit() {

  }

  scrollTo(elementId: string) {
      let y = document.getElementById(elementId).offsetTop;
      this.content.scrollToPoint(0, y, 1000);
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
