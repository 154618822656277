import { Injectable } 						from '@angular/core';
import { HttpClient, HttpHeaders } 			from '@angular/common/http';
import { Observable, of } 					from 'rxjs';
import { catchError, map, tap } 			from 'rxjs/operators';
import { Router } from  "@angular/router";

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})

export class ApiService {

	// private BASE_URL  					=  'https://api.jombola.com.my/api';
	private BASE_URL  					=  'https://jombola-api.alteraa.com/api';
	// private BASE_URL  					=  'http://localhost:8000/api';
	// private BASE_URL  					=  'http://192.168.0.8:8000/api';
	// private BASE_URL  					=  'http://192.168.0.2:8000/driver';

	// private INDEX_URL  					=  (this.BASE_URL + '/');

	// AUTH
	private REGISTER_URL				= this.BASE_URL + '/register';
	private LOGIN_URL					= this.BASE_URL + '/login';
	private PROFILE_URL					= this.BASE_URL + '/user/profile';
	private PROFILEOPTIONS_URL			= this.BASE_URL + '/user/profile/options';

	private CREATETEAM_URL				= this.BASE_URL + '/user/team';
	private TEAMOPTIONS_URL				= this.BASE_URL + '/user/team/options';
	private GETTEAMS_URL				= this.BASE_URL + '/user/teams';
	private GETTEAM_URL					= this.BASE_URL + '/user/teams/';
		
	private SQUADINDEX_URL				= this.BASE_URL + '/team/squad/';
	private GETMEMBER_URL				= this.BASE_URL + '/team/members/';
	private INVITEMEMBER_URL			= this.BASE_URL + '/team/member';
	private CANCELINVITE_URL			= this.BASE_URL + '/team/invite/';
	private ASSIGNPOSITION_URL			= this.BASE_URL + '/team/member/position';
	private ENDPOSITION_URL				= this.BASE_URL + '/team/member/position/';
	private REMOVEMEMBER_URL			= this.BASE_URL + '/team/member/';
	
	private STATSINDEX_URL				= this.BASE_URL + '/team/stats/';
	
	private HOMEPAGE_URL				= this.BASE_URL + '/user/home';
	private HOMEPAGETEAMRESPOND_URL		= this.BASE_URL + '/user/respondTeam';
	private HOMEPAGETRAININGRESPOND_URL	= this.BASE_URL + '/user/respondTraining';
	
	private BOOKING_URL					= this.BASE_URL + '/user/booking';
	
	private TRAININGOPTIONS_URL			= this.BASE_URL + '/user/training/options';
	private TRAINING_URL				= this.BASE_URL + '/user/training';
	
	private TRAININGINDEX_URL			= this.BASE_URL + '/training/index/';
	private TRAININGATTENDANCE_URL		= this.BASE_URL + '/training/attendance/';







  	constructor(private http:  HttpClient, private  router:  Router) { }

  	private handleError<JSON> (operation = 'operation', result?: JSON) {
	  return (error: any): Observable<JSON> => {
  		if(error.status === 401 || error.status === 400) {

  			localStorage.removeItem('user');
  			this.router.navigate(['']);
  		}
	    console.log(error);
	    result = error.error;
	    console.log(result);
	    return of(result as JSON);
  	}};

  	headers(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        // .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
	        // .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Content-Type, Authorization, X-Requested-With, A-Auth-Token')
	        // .append('Access-Control-Allow-Origin', 'http://localhost:4200')
	        .append('Content-Type', 'application/json');
	        // .append('Authorization', "<<my token>>");

	}

	fileHeaders(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        // .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
	        // .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Content-Type, Authorization, X-Requested-With, A-Auth-Token')
	        // .append('Access-Control-Allow-Origin', 'http://localhost:4200')
	        .append('Accept', 'video/*')
	        .append('Accept', 'application/pdf');

	}

	getToken(): string {
		if (localStorage.getItem('user') !== null) {
			const json = JSON.parse(localStorage.getItem('user'));

			if (!json.token) {
				return '';
			}

			return json.token;
		}
		return null;
	}

	getTrainingAttendanceScanUrl(trainingId, userId): string {

		return this.TRAININGATTENDANCE_URL + trainingId + '/' + userId;
	}

	updateTrainingAttendance(url): Observable<any> {
		return this.http.get(url, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('updateTrainingAttendance'))
	  	);
	}

	trainingIndex(id): Observable<any> {
		return this.http.get(this.TRAININGINDEX_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('trainingIndex'))
	  	);
	}

	register(param: any): Observable<any> {
	  return this.http.post(this.REGISTER_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('register'))
	  	);
	}

	login(param: any): Observable<any> {
	  return this.http.post(this.LOGIN_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('login'))
	  	);
	}

	profileOptions(): Observable<any> {
	  return this.http.get(this.PROFILEOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profileOptions'))
	  	);
	}

	profile(): Observable<any> {
	  return this.http.get(this.PROFILE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	createProfile(param: any): Observable<any> {
	  return this.http.post(this.PROFILE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('createProfile'))
	  	);
	}

	updateProfile(param: any): Observable<any> {
	  return this.http.patch(this.PROFILE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('updateProfile'))
	  	);
	}


	teamOptions(): Observable<any> {
	  return this.http.get(this.TEAMOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('teamOptions'))
	  	);
	}

	createTeam(param: any): Observable<any> {
	  return this.http.post(this.CREATETEAM_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('createTeam'))
	  	);
	}

	getTeams(): Observable<any> {
	  return this.http.get(this.GETTEAMS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getTeams'))
	  	);
	}

	getTeam(id): Observable<any> {
	  return this.http.get(this.GETTEAM_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getTeam'))
	  	);
	}

	squadIndex(id): Observable<any> {
	  return this.http.get(this.SQUADINDEX_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('squadIndex'))
	  	);
	}

	getMember(code): Observable<any> {
	  return this.http.get(this.GETMEMBER_URL + code, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getMember'))
	  	);
	}

	inviteMember(param: any): Observable<any> {
	  return this.http.post(this.INVITEMEMBER_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('createTeam'))
	  	);
	}

	cancelInvite(id: any): Observable<any> {
	  return this.http.delete(this.CANCELINVITE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('createTeam'))
	  	);
	}

	addPosition(param: any): Observable<any> {
	  return this.http.post(this.ASSIGNPOSITION_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('addPosition'))
	  	);
	}

	endPosition(id): Observable<any> {
	  return this.http.delete(this.ENDPOSITION_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('endPosition'))
	  	);
	}

	removeMember(id): Observable<any> {
	  return this.http.delete(this.REMOVEMEMBER_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('endPosition'))
	  	);
	}

	// assignMember(param: any): Observable<any> {
	//   return this.http.post(this.ASSIGNPOSITION_URL, param, { headers: this.headers() })
	//   	.pipe(
	//     	catchError(this.handleError<JSON>('assignMember'))
	//   	);
	// }

	statsIndex(id): Observable<any> {
	  return this.http.get(this.STATSINDEX_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('squadIndex'))
	  	);
	}



	getHomepage(): Observable<any> {
	  return this.http.get(this.HOMEPAGE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getHomepage'))
	  	);
	}

	respondTeam(param: any): Observable<any> {
	  return this.http.patch(this.HOMEPAGETEAMRESPOND_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('respondTeam'))
	  	);
	}
	
	respondTraining(param: any): Observable<any> {
	  return this.http.patch(this.HOMEPAGETRAININGRESPOND_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('respondTeam'))
	  	);
	}

	createBooking(param: any): Observable<any> {
	  return this.http.post(this.BOOKING_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('addPosition'))
	  	);
	}


	cancelBooking(id): Observable<any> {
	  return this.http.delete(this.BOOKING_URL + '/' + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('addPosition'))
	  	);
	}

	trainingOptions(): Observable<any> {
	  return this.http.get(this.TRAININGOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('trainingOptions'))
	  	);
	}

	createTraining(param: any): Observable<any> {
	  return this.http.post(this.TRAINING_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('addPosition'))
	  	);
	}



}
