import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
// import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } 			from '@angular/forms';
import { ApiService } 									from  '../api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-tab5-edit',
  templateUrl: './tab5-edit.page.html',
  styleUrls: ['./tab5-edit.page.scss'],
})
export class Tab5EditPage implements OnInit {

	submitted = false;
	data: any;
	options: any;
	cities: any;
	form: FormGroup;
	selectedState: {};

  classItem: {position_id: number}[] = [];
  @ViewChild(IonContent, {static: true}) content: IonContent;


  constructor(private modalController: ModalController,
  		private navParams: NavParams,
  		private fb: FormBuilder,
  		private apiService: ApiService) {}

  get f() { return this.form.controls; }

  ngOnInit() {
  	this.data          = this.navParams.get('data');
  	this.options       = this.navParams.get('options');

  	this.form = this.fb.group({
			name: 			['', 	[Validators.required]],
			email: 			['', 	[Validators.required, Validators.email]],
			contact_number: ['', 	[Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
			date_of_birth: 	['',	[]],
			gender: 		['', 	[]],
			nationality_id: ['', 	[Validators.required]],
			city_id: 		['', 	[Validators.required]],
			state_id: 		['', 	[Validators.required]],
			preferred_foot: ['', 	[Validators.required]],
      positions: [ this.classItem,         [Validators.required, Validators.minLength(1)]],
			official_name: 	['', 	[]],
			official_id: 	['', 	[Validators.pattern('^[0-9]*$'), Validators.minLength(12)]],
			facebook_link: 	['', 	[]],
			google_link: 	['', 	[]],
			twitter_link: 	['', 	[]],
			linkedin_link: 	['', 	[]],
		});

    this.classItem = []; 
  	this.index();
  
    

  }

  index() {

  	this.form.controls['name'].setValue(this.data.user.name);
  	this.form.controls['email'].setValue(this.data.user.email);

  	if(this.data.profile) {

	  	this.form.controls['contact_number'].setValue(this.data.profile.contact_number);
	  	this.form.controls['date_of_birth'].setValue(this.data.profile.date_of_birth);
	  	this.form.controls['gender'].setValue(this.data.profile.gender);
	  	this.form.controls['nationality_id'].setValue(this.data.profile.nationality_id);
	  	this.form.controls['city_id'].setValue(this.data.profile.city_id);
	  	this.form.controls['state_id'].setValue(this.data.profile.state_id);
	  	this.form.controls['preferred_foot'].setValue(this.data.profile.preferred_foot);
	  	this.form.controls['official_name'].setValue(this.data.profile.official_name);
	  	this.form.controls['official_id'].setValue(this.data.profile.official_id);
	  	this.form.controls['facebook_link'].setValue(this.data.profile.facebook_link);
	  	this.form.controls['google_link'].setValue(this.data.profile.google_link);
	  	this.form.controls['twitter_link'].setValue(this.data.profile.twitter_link);
	  	this.form.controls['linkedin_link'].setValue(this.data.profile.linkedin_link);

	  	this.stateChanged(null);

      this.initPositions();

	  }
  }

  stateChanged(event) {

  	// alert($event.target.value);
  	// this.selectedState = event.target.value;
  	// console.log(this.selectedState);
  	
  	// this.form.controls['state_id'].setValue(this.selectedState['id']);
  	// this.form.controls['city_id'].setValue('');
  	if(event !== null)
  		this.cities = (this.options.states.filter(state => state.id == event.target.value))[0].cities;
  	else
  		this.cities = (this.options.states.filter(state => state.id == this.form.controls['state_id'].value))[0].cities;
  }

  dateChanged(event) {

  	if(event.target.value !== this.form.controls['date_of_birth'].value)
  		this.form.controls['date_of_birth'].setValue(moment(event.target.value).format('YYYY-MM-DD'));
  }

  initPositions() {

    if(this.options.positions !== null) {
      for (var i = this.options.positions.length - 1; i >= 0; i--) {

        // const exist = this.options.positions.some(posi => posi.id === )
        const exist = this.data.profile.user_profile_positions.find(profilePosi => profilePosi.position_id === this.options.positions[i].id);
            this.options.positions[i]['checked'] = exist;
          
          if(exist)  
            this.classItem.push({
              'position_id' : this.options.positions[i]['id'],
            });
      }
      this.form.controls['positions'].setValue(this.classItem);
    }
  }

  onPositionChange(event, position) {

    if(position && event) {
      if(event.target.checked)
        this.classItem.push({
          'position_id' : position['id'],
        });
      else {
        this.classItem = this.classItem.filter(item => item.position_id !== position['id']);
      }
      
      this.form.controls['positions'].setValue(this.classItem);
    }
  }

  submit() {
  	this.submitted = true;

  	if(this.form.valid) {

  		if(this.data.profile) {

        this.apiService.updateProfile(this.form.value).subscribe(data => {
          this.submitted = false;  
          
          if (data.status == true) {
            this.dismissModal();
          } else {
            alert(data.message);
          }
        });
  		}
  		else {
  			this.apiService.createProfile(this.form.value).subscribe(data => {
  				this.submitted = false;  
  				
  				if (data.status == true) {
  					this.dismissModal();
  				} else {
  					alert(data.message);
  				}
  			});
  		}
  	}
  }

  scrollTo(elementId: string) {
      let y = document.getElementById(elementId).offsetTop;
      this.content.scrollToPoint(0, y, 1000);
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  
}
