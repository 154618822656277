import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tab1-events-details',
  templateUrl: './tab1-events-details.page.html',
  styleUrls: ['./tab1-events-details.page.scss'],
})
export class Tab1EventsDetailsPage implements OnInit {

  courtType: any;
  interval: any;
  days: any = "..";
  hours: any = "..";
  mins: any = "..";
  secs: any = "..";

	@ViewChild(IonContent, {static: true}) content: IonContent;


  constructor(private modalController: ModalController,
  		private data: NavParams) {}

  ngOnInit() {
    this.startCountdown();
  }

  ionViewDidLeave() {

    this.dismissModal();
  }

  scrollTo(elementId: string) {
      let y = document.getElementById(elementId).offsetTop;
      this.content.scrollToPoint(0, y, 1000);
  }

  dismissModal() {
    clearInterval(this.interval);
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  startCountdown() {

    let countDownDate = new Date("June 1, 2021 08:00:00").getTime();

    // Update the count down every 1 second
    this.interval = setInterval(function () {

      // Get todays date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let secs = Math.floor((distance % (1000 * 60)) / 1000);
      console.log(now, "now", "countDownDate", countDownDate, "distance", distance, "days", days);

      // Output the result in an element with id="demo"
      document.getElementById("days").innerHTML = "" + days;
      document.getElementById("hours").innerHTML = "" + hours;
      document.getElementById("mins").innerHTML = "" + mins;
      document.getElementById("secs").innerHTML = "" + secs;
      //   + minutes + "m " + seconds + "s ";

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(this.interval);
        // document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  }
}
