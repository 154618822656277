import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Tab5EditPage } from './tab5-edit.page';

const routes: Routes = [
  {
    path: '',
    component: Tab5EditPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class Tab5EditPageRoutingModule {}
