import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Tab1EventsDetailsPageModule } from './tab1-events-details/tab1-events-details.module';
import { Tab1MatchesDetailsPageModule } from './tab1-matches-details/tab1-matches-details.module';
import { Tab5EditPageModule } from './tab5-edit/tab5-edit.module';
import { Tab4EditPageModule } from './tab4-edit/tab4-edit.module';
import { Tab4TeamPageModule } from './tab4-team/tab4-team.module';
import { TabsTrainingPageModule } from './tabs-training/tabs-training.module';
import { TabsTrainingAddPageModule } from './tabs-training-add/tabs-training-add.module';
import { Tab1CourtsDetailsPageModule } from './tab1-courts-details/tab1-courts-details.module';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule }            from  '@angular/common/http';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [	
  	BrowserModule, 
  	IonicModule.forRoot(), 
  	AppRoutingModule, 
  	Tab1EventsDetailsPageModule, 
  	Tab1MatchesDetailsPageModule,
    Tab5EditPageModule,
    Tab4EditPageModule,
    Tab4TeamPageModule,
    TabsTrainingPageModule,
    TabsTrainingAddPageModule,
    Tab1CourtsDetailsPageModule,
    HttpClientModule,
    TabsTrainingAddPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
