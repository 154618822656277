import { Component, ViewChild, OnInit } from '@angular/core';
import { IonContent, ModalController, AlertController, NavParams } from '@ionic/angular';
import { BarcodeScannerOptions, BarcodeScanner} from "@ionic-native/barcode-scanner/ngx";
import { ApiService }                   from  '../api.service';
import * as moment from 'moment';

// import {
//   BarcodeScannerOptions,
//   BarcodeScanner,
// } from "@ionic-native/barcode-scanner/ngx";

@Component({
  selector: 'app-tabs-training',
  templateUrl: './tabs-training.page.html',
  styleUrls: ['./tabs-training.page.scss'],
})



export class TabsTrainingPage implements OnInit {

data:any;
attendanceData:any;
title = 'app';
  elementType = 'url';
  value: any;
  loading = false;
  
  showAttending  = false;
  showAttended   = false;
  QRAvailable   = false;


  barcodeScannerOptions: BarcodeScannerOptions;

  
  @ViewChild(IonContent, {static: true}) content: IonContent;

  constructor(
  	private modalController: ModalController,
    private navParams: NavParams,
    private barcodeScanner: BarcodeScanner,
    private apiService: ApiService
    ) { 
  	
    this.barcodeScannerOptions = {
        preferFrontCamera : false, // iOS and Android
        showFlipCameraButton : false, // iOS and Android
        showTorchButton : true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        // saveHistory: true, // Android, save scan history (default false)
        prompt : "Scan QR in the boxed outline displayed", // Android
        resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        // formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        // disableAnimations : true, // iOS
        disableSuccessBeep: false // iOS and Android
    };
  }

  ngOnInit() {
    this.data         = this.navParams.get('data');	

    this.checkQRAvailability();
    this.loadAttendance(this.data.id);
    // console.log(this.data);
    // this.value = "https://api.jombola.com.my/training/attendance/" + this.data.id;
    // this.encodeData = "Hello/" + this.data.id;

  }

  checkQRAvailability() {

    let trainingDateTime = this.data.booking.start_hours;
    let availableTime = moment(trainingDateTime).subtract(30, 'minutes').format('YYYY-MM-DDTHH:mm');

    if(moment().format('YYYY-MM-DDTHH:mm') >= availableTime)
      this.QRAvailable = true;
  }
  loadAttendance(id) {

    this.loading = true;

    this.apiService.trainingIndex(id).subscribe(data => {

            this.loading = false;
            if (data.status == true) {
              this.attendanceData = data.data;
            } else {
              alert(data.message);
            }
          });


  }

  dismissModal(status = false) {

    this.modalController.dismiss({
      'dismissed': true,
      'status': status ? true: false
    });
  }

  showQR() {
  	this.value = this.apiService.getTrainingAttendanceScanUrl(this.data.id, this.getUser()['id']);
  }

  scanQR() {

    this.barcodeScanner
      .scan(this.barcodeScannerOptions)
      .then(barcodeData => {

        if(!barcodeData['cancelled']) {
          this.loading = true;

          this.apiService.updateTrainingAttendance(barcodeData['text']).subscribe(data => {

            this.loading = false;
            alert(data.message);
            
            if (data.status == true) {
              this.dismissModal(data);
            }
          });
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  isOwnBooking(): boolean {

    let user = this.getUser();
    
    if(user)
      return user.id === this.data.booking.added_by_user_id;
    // if (localStorage.getItem('user') !== null) {
    //   const user = JSON.parse(localStorage.getItem('user'));

    //   if (user) {
    //     let own = ;
    //     return own;
    //   }
    // }
    // return false;
  }

  getUser() {
      if (localStorage.getItem('user') !== null) {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
          return user;
        }
      }
      return null;
  }
}
